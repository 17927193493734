import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
const getImages = graphql`
  query {
    fluid: file(relativePath: { eq: "computer.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const ValuesMissionSection = ({ intl }) => {
  const data = useStaticQuery(getImages)
  return (
    <section className="image-bg overlay parallax">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <BackgroundImage
          style={{
            width: '100%',
            height: '100%',
            opacity: '0.8'
          }}
          alt="Background"
          className="background-image"
          fluid={data.fluid.childImageSharp.fluid}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="mb16">Appify IT</h2>
            <p className="lead mb64">
              {intl.formatMessage({id: 'footer.itPartner' })}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="feature feature-1 boxed" style={{ minHeight: '380px' }}>
              <div className="text-center">
                <i className="ti-package icon" />
                <h5 className="uppercase">
                  {intl.formatMessage({
                    id: 'landingPage.valuesMissionSection.val_1_title'
                  })}
                </h5>
              </div>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_1_desc'
                })}
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="feature feature-1 boxed" style={{ minHeight: '380px' }}>
              <div className="text-center">
                <i className="ti-infinite icon" />
                <h5 className="uppercase">
                  {intl.formatMessage({
                    id: 'landingPage.valuesMissionSection.val_2_title'
                  })}
                </h5>
              </div>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_2_desc'
                })}
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="feature feature-1 boxed" style={{ minHeight: '380px' }}>
              <div className="text-center">
                <i className="ti-heart icon" />
                <h5 className="uppercase">
                  {intl.formatMessage({
                    id: 'landingPage.valuesMissionSection.val_3_title'
                  })}
                </h5>
              </div>
              <p style={{ textAlign: 'center' }}>
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.1'
                })}
                <br />
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.2'
                })}
                <br />
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.3'
                })}
                <br />
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.4'
                })}
                <br />
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.5'
                })}
                <br />
                {intl.formatMessage({
                  id: 'landingPage.valuesMissionSection.val_3.6'
                })}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(ValuesMissionSection)
