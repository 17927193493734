import React from 'react'
import Layout from '../components/Layout'
import IntroductionSection from '../components/LandingPage/IntroductionSection'
import ServicesSection from '../components/LandingPage/ServicesSection'
import ValuesMissionSection from '../components/LandingPage/ValuesMissionSection'
import SEO from '../components/SEO'

const index = () => {
  return (
    <Layout>
      <SEO title="Web & Apps" />
      <IntroductionSection />
      <ServicesSection />
      <ValuesMissionSection />
    </Layout>
  )
}

export default index
