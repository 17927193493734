import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
const getImages = graphql`
  query {
    fluid: file(relativePath: { eq: "phone2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const ServicesSection = ({ intl }) => {
  const data = useStaticQuery(getImages)
  return (
    <section id="dynamic-padding" className="image-edge pb0">
      <div className="col-md-6 col-sm-4 p0 col-md-push-6 col-sm-push-8">
        <Img
          alt="Screenshot"
          className="mb-xs-24"
          fluid={data.fluid.childImageSharp.fluid}
        />
      </div>
      <div className="container">
        <div className="col-md-5 col-md-pull-0 col-sm-7 col-sm-pull-4 v-align-transform">
          <h1 className="large mb64 mb-xs-24">
            {intl.formatMessage({
              id: 'landingPage.serviceSection.title'
            })}
          </h1>
          <div className="feature feature-3">
            <div className="left">
              <i className="ti-gallery icon-sm" />
            </div>
            <div className="right">
              <h5 className="uppercase mb16">
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_1_title'
                })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_1_desc'
                })}
              </p>
            </div>
          </div>

          <div className="feature feature-3">
            <div className="left">
              <i className="ti-layers icon-sm" />
            </div>
            <div className="right">
              <h5 className="uppercase mb16">
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_2_title'
                })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_2_desc'
                })}
              </p>
            </div>
          </div>

          <div className="feature feature-3">
            <div className="left">
              <i className="ti-layers icon-sm" />
            </div>
            <div className="right">
              <h5 className="uppercase mb16">
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_3_title'
                })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_3_desc'
                })}
              </p>
            </div>
          </div>

          <div className="feature feature-3">
            <div className="left">
              <i className="ti-layers icon-sm" />
            </div>
            <div className="right">
              <h5 className="uppercase mb16">
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_4_title'
                })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'landingPage.serviceSection.service_4_desc'
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(ServicesSection)
