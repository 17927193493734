import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import video from '../../../static/video/building-apps-video.mp4'
import { injectIntl, Link } from 'gatsby-plugin-intl'

const getImages = graphql`
  query {
    fluid: file(relativePath: { eq: "home2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const IntroductionSection = ({ intl }) => {
  const data = useStaticQuery(getImages)
  return (
    <section className="cover image-bg fullscreen overlay overlay-heavy bg-dark vid-bg pt-xs-120">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <BackgroundImage
          alt="image_b"
          style={{
            textAlign: 'center'
          }}
          className="background-image"
          fluid={data.fluid.childImageSharp.fluid}
        >
          <video style={{ height: '100vh' }} autoPlay muted loop id="myVideo">
            <source src={video} type="video/mp4" />
          </video>
        </BackgroundImage>
      </div>
      <div className="container v-align-transform">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
            <h1 className="large" style={{ fontWeight: '400' }}>
              Appify IT !
              <br />{' '}
              {intl.formatMessage({
                id: 'landingPage.introductionSection.parag_1'
              })}
            </h1>
            <p className="lead mb40 mb-xs-24" style={{fontSize: 'large', fontWeight: '600'}}>
              {intl.formatMessage({
                id: 'landingPage.introductionSection.parag_2'
              })}
              <br />
              {intl.formatMessage({
                id: 'landingPage.introductionSection.parag_3'
              })}
            </p>
            <Link className="btn btn-lg btn-white" to="/contact">
              {intl.formatMessage({
                id: 'landingPage.introductionSection.btn'
              })}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(IntroductionSection)
